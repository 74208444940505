import AppButton from "components/AppButton/AppButton";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import GroupSearch from "./GroupSearch";
import { Modal, Typography } from "antd";

const GroupSearchButton = props => {
  const [resetSearch, setResetSearch] = useState(false);
  const { modalStore, initialSearchTerm, ...restProps } = props;
  const { Title } = Typography;
  return (
    <>
      <AppButton
        onClick={() => modalStore.toggleModal("showGroupSearchButton", true)}
        label="Find Contact"
        light
        size="large"
        prefixIcon={<i className="fas fa-search me-2" />}
        {...restProps}
        style={{ margin: "10px 0" }}
      />
      {modalStore.showGroupSearchButton && (
        <Modal
          title={
            <div style={{ textAlign: "center" }}>
              <Title level={4} style={{ fontWeight: "bold", margin: 0 }}>
                Search Contact
              </Title>
            </div>
          }
          visible={modalStore.showGroupSearchButton}
          onCancel={() => modalStore.toggleModal("showGroupSearchButton", false)}
          footer={null}
          width={600}
        >
          {!initialSearchTerm && (
            <p className="text-muted mt-3" style={{ textAlign: "center" }}>
              <i className="bx bx-search-alt align-middle fs-3 me-2"></i>
              Search groups by contact number or contact name
            </p>
          )}
          <div className="w-100">
            <GroupSearch resetSearch={resetSearch} onResetComplete={() => setResetSearch(false)} initialSearchTerm={initialSearchTerm} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(GroupSearchButton));
