import React, { useRef, useState, useEffect } from "react";
import { Col, Row, Typography, Popconfirm } from "antd";
import FormCheckBoxInput from "components/FormItems/FormCheckBoxInput";
import FormInput from "components/FormItems/FormInput";
import MessageBox from "components/GatewayMessage/MessageBox/MessageBox";
import CharactersCounter from "components/GatewayMessage/SmsCounter/Characters";
import { WarningOutlined } from "@ant-design/icons";
import AddTemplate from "components/GatewayMessage/Buttons/AddTemplate";
import SendTestSMS from "components/GatewayMessage/Buttons/SendTestSMS";
import AddShortUrl from "components/GatewayMessage/Buttons/AddShortUrl";
import AddMergeLabel from "components/GatewayMessage/Buttons/AddMergeLabel";
import { optOutMessageSample, replyLinkMessageSample, replyStopMessageSample, shortUrlSample } from "constant/messageConstants";
import AttachFile from "./Buttons/AttachFile";
import { inject, observer } from "mobx-react";
import CampaignSchedule from "./CampaignSchedule/CampaignSchedule";
import CampaignOptout from "./CampaignOtpOut/CampaignOptOut";
import { toJS } from "mobx";
import { subjectMaxLength } from "constant/constant";
import clsx from "clsx";
import EmojiPicker from "./Buttons/EmojiPicker";
import RetailChatInvoice from "./Buttons/RetailChatInvoice";

const popUpTitle = (
  <div style={{ maxWidth: "20rem" }}>
    As per ACMA guidelines, all marketing or promotional messages must allow the recipient to opt-out from receiving messages in the future. For more information please visit &nbsp;
    <a href={"https://www.acma.gov.au/"} target="_blank" rel="noopener noreferrer">
      www.acma.gov.au
    </a>
    . Are you sure to exclude opt-out from campaign?
  </div>
);

const MessageContainer = ({
  label = "Enter Message",
  hide = false,
  formikRef,
  setFieldValue,
  handleChange,
  errors,
  touched,
  values,
  colSpan = 24,
  colMd = 24,
  name,
  quickMessage = false,
  minRows = 8,
  optOut,
  isMms = false,
  messageStore,
  smallSize = false,
  optoutCheckBox = true,
  addTemplateBtn = true,
  shortUrl = true,
  testSMS = true,
  authStore,
  scheduler = false,
  endProp,
  campaign = false,
  size = "large",
  handleSubmit,
  fileAttachBtn = true,
  setFileAttachBtn,
  zIndex,
  subject = true,
  emoji = true,
  retailChatInvoice = false,
  quickTestSMS = false,
  modalStore,
  isTemplate = false
}) => {
  const [message, setMessage] = useState(values?.message ? values.message : "");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [hidden, setHidden] = useState(false);
  const [contactNum, setContactNum] = useState(0);
  const textAreaRef = useRef();
  const checkBoxRef = useRef();

  const { balanceObj, user } = authStore;
  const { showCreateTemplateModal } = modalStore;

  useEffect(() => {
    if (hide) setHidden(true);
    else setHidden(false);
  }, [hide]);

  // const data = localStorage?.getItem("campaign");
  // const parsedData = JSON?.parse(data);
  // const groupLength = parsedData?.group?.length;

  useEffect(() => {
    if (values?.groupObj) {
      setContactNum(parseInt(Array.isArray(values?.groupObj) ? values?.totalContacts : values?.groupObj?.totalContacts));
    }
    if ((values.media || values.url) && !showCreateTemplateModal) {
      (values.media && messageStore.setCurrentImage(values.media) && setFieldValue("url", undefined)) ||
        (values.url && messageStore.setCurrentImage(values.url, true) && setFieldValue("media", undefined));
      messageStore.setData("mediaType", values.mediaType);
      messageStore.setData("mediaName", values.mediaName);
    }
    if (values?.contacts && values?.contacts.length > 0) setContactNum(values.contacts.length);
  }, [values]);

  useEffect(() => {
    if (message && !message.includes(shortUrlSample)) formikRef.current?.setFieldValue("longUrl", undefined);
  }, [message]);

  useEffect(() => {
    if (isMms) {
      setFieldValue("replyLink", false);
      setFieldValue("replyLinkVisible", false);
    }
    messageStore.setCurrentImage();
  }, [isMms]);

  function textInsertion(e, values, setFieldValue, name, url = false, template = false) {
    const target = textAreaRef.current;
    target.focus();
    const content = values[name] ? values[name].trim() : "";
    let textToInsert = url ? `${shortUrlSample}` : template ? `${e}` : `{{[${e}]}}`;
    let cursorPosition = target.selectionStart;
    let textBeforeCursorPosition = content ? (cursorPosition >= 1 ? `${content.substring(0, cursorPosition)} ` : content.substring(0, cursorPosition)) : "";
    let textAfterCursorPosition = content ? ` ${content.substring(cursorPosition, values.length)}` : "";
    const combinedMsg = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
    if (!url) {
      setFieldValue(name, combinedMsg);
      target.value = combinedMsg;
      target.focus();
      target.selectionEnd = textBeforeCursorPosition.length + textToInsert.length;
    } else {
      if (values[name] && values[name].includes(shortUrlSample)) {
        values[name].replace(shortUrlSample, shortUrlSample);
        target.focus();
        target.selectionEnd = textBeforeCursorPosition.length;
      } else {
        setFieldValue(name, combinedMsg);
        target.value = combinedMsg;
        target.focus();
        target.selectionEnd = textBeforeCursorPosition.length + textToInsert.length;
      }
    }
  }

  const addEmoji = (e, values, setFieldValue) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach(el => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    textInsertion(emoji, values, setFieldValue, name, false, true);
  };

  function handleFileUpload(file) {
    if (file) {
      if (!showCreateTemplateModal) {
        messageStore.setCurrentImage(file.media);
        messageStore.setData("mediaType", file.type);
        messageStore.setData("mediaName", file.fileName);
      }
      setFieldValue("url", undefined);
      setFieldValue("media", file.media);
      setFieldValue("mediaType", file.type);
      setFieldValue("mediaName", file.fileName);
    }
  }
  return (
    <>
      {isMms && subject && (
        <>
          {!quickMessage && (
            <Col span={colSpan} md={colMd}>
              <Typography.Title level={4} className="fs-6">
                Subject
              </Typography.Title>
            </Col>
          )}
          <Col span={colSpan} md={colMd}>
            <FormInput
              containerProps={{ colon: false }}
              label={quickMessage && <span className="fw-bold">Subject</span>}
              name="subject"
              error={errors.subject}
              touched={touched.subject}
              placeholder="Enter Subject"
              onChange={e => {
                handleChange(e);
                !showCreateTemplateModal && messageStore.setPhoneSubject(e.target.value);
              }}
              value={values.subject}
              size="default"
              containerClassname="mb-0"
              inputProps={{
                maxLength: subjectMaxLength
              }}
            />
          </Col>
        </>
      )}
      {label && (
        <Col span={colSpan} md={colMd}>
          <Typography.Title level={4} className="fs-6">
            {label}
          </Typography.Title>
        </Col>
      )}
      <Col span={colSpan} md={colMd}>
        <Row>
          {!hidden && (
            <Col span={24} className="border border-bottom-0 rounded-top gap-2 p-2 bg-light d-flex flex-wrap">
              {((quickMessage && values?.numberList && values.numberList.some(obj => obj.hasOwnProperty("contactData"))) ||
                (values?.groupObj
                  ? Array.isArray(values.groupObj)
                    ? values.groupObj.some(obj => Array.isArray(obj.fields) && obj.fields.length > 0)
                    : values?.groupObj?.fields && values?.groupObj?.fields?.length !== 0
                  : false)) && (
                <AddMergeLabel
                  fieldToSearch="contactData"
                  onClick={text => {
                    let e = text.target.innerText;
                    textInsertion(e, values, setFieldValue, name);
                  }}
                  labels={
                    quickMessage
                      ? { fields: ["first_name", "last_name"] }
                      : Array.isArray(values.groupObj)
                      ? {
                          fields: [
                            ...values.groupObj.reduce((set, obj) => {
                              obj.fields.forEach(field => {
                                if (field) set.add(field);
                              });
                              return set;
                            }, new Set())
                          ]
                        }
                      : values?.groupObj
                  }
                />
              )}
              {emoji && <EmojiPicker onEmojiSelect={e => addEmoji(e, values, setFieldValue)} />}
              {addTemplateBtn && (
                <AddTemplate
                  zIndex={zIndex}
                  setFileAttachBtn={setFileAttachBtn}
                  onSubmit={template => {
                    if (setFileAttachBtn) setFileAttachBtn(true);
                    textInsertion(template.content, values, setFieldValue, name, false, true);
                    if (isMms) {
                      template.subject && setFieldValue("subject", template.subject);
                      template.media && setFieldValue("url", template.media) && setFieldValue("mediaName", "File Attached") && setFieldValue("media", undefined);
                    }
                  }}
                  size={quickMessage ? "small" : size}
                  smallSize={smallSize}
                  isMms={isMms}
                />
              )}
              {shortUrl && (
                <AddShortUrl
                  onSubmit={longUrl => {
                    setFieldValue("longUrl", longUrl);
                    textInsertion(longUrl, values, setFieldValue, name, true);
                  }}
                  size={quickMessage ? "small" : size}
                />
              )}
              {testSMS && (
                <SendTestSMS
                  values={values}
                  isMms={isMms}
                  size={quickMessage || quickTestSMS ? "small" : size}
                  quickMessage={quickMessage || quickTestSMS}
                  saveAndContinue={handleSubmit}
                  setFieldValue={setFieldValue}
                  name={name}
                  isFirstTime={isFirstTime}
                  setIsFirstTime={setIsFirstTime}
                />
              )}
              {isMms && fileAttachBtn && (!showCreateTemplateModal || isTemplate) && <AttachFile onSubmit={handleFileUpload} values={values} size={quickMessage ? "small" : size} />}
              {retailChatInvoice && <RetailChatInvoice />}
            </Col>
          )}
          <Col span={24} className="border border-bottom-0 p-0">
            <MessageBox
              containerClassname="m-0"
              name={name}
              value={values[name]}
              onChange={e => {
                handleChange(e);
                setMessage(e.target.value);
              }}
              placeholder={"Type a message"}
              textAreaRef={textAreaRef}
              minRows={minRows}
            />
          </Col>
          <Col
            span={24}
            className={clsx("border border-top-0 py-0 px-3", {
              "border-bottom-0": !hidden
            })}
          >
            <div className="d-flex align-items-start mx-2">
              <Typography.Text className="fs-6">
                {values.replyStopToOptOut && replyStopMessageSample}
                {values.optout && optOutMessageSample}
                {values.replyLink && replyLinkMessageSample}
              </Typography.Text>
            </div>
          </Col>
          {!hidden && (
            <Col span={24} className="border border-top-0 py-0 px-3 rounded-bottom">
              <CharactersCounter
                formikRef={formikRef}
                message={values[name]}
                setMessage={e => {
                  setFieldValue(name, e);
                }}
                name={name}
                isMms={isMms}
                setFieldValue={setFieldValue}
                values={values}
                balance={balanceObj ? (isMms ? balanceObj?.maxPossibleCredits?.mms : balanceObj?.maxPossibleCredits?.sms) : 0}
                contacts={contactNum}
                smallSize={smallSize}
              />
            </Col>
          )}
          {errors[name] && touched[name] && (
            <Col span={24}>
              <Typography.Text level={4} className="text-danger-color fs-6">
                {errors[name]}
              </Typography.Text>
            </Col>
          )}
        </Row>
      </Col>
      {optoutCheckBox && (
        <Col span={colSpan} md={colMd}>
          {campaign ? (
            <CampaignOptout
              values={values}
              setFieldValue={setFieldValue}
              name={quickMessage ? "replyLink" : optOut}
              label={quickMessage ? "Add Reply Link" : optOut == "replyStopToOptOut" ? "Reply Stop" : "Unsubscribe link"}
              disabled={values.campaign_type === "notification"}
            />
          ) : (
            <>
              {!user?.quickApproved && (
                <Popconfirm
                  title={popUpTitle}
                  icon={<WarningOutlined />}
                  onConfirm={() => {
                    setFieldValue(optOut, false);
                  }}
                  onCancel={() => {
                    setFieldValue(optOut, true);
                  }}
                  okText="Confirm"
                  cancelText="Discard"
                  placement="topLeft"
                  okButtonProps={{
                    style: {
                      border: "var(--main-color) 1px solid",
                      background: "var(--main-color)"
                    }
                  }}
                  disabled={!(values.optout ^ values.replyStopToOptOut)}
                >
                  <div
                    className={quickMessage && !isMms && (user?.senders.includes(values.sender) || values.replyLinkVisible) ? "d-block" : quickMessage ? "d-none" : "d-block"}
                    style={{
                      width: "fit-content"
                    }}
                  >
                    <FormCheckBoxInput
                      containerClassname="mb-0"
                      values={values}
                      handleChange={e => {
                        if (!quickMessage) {
                          setFieldValue(optOut, true);
                        } else {
                          setFieldValue("replyLink", e.target.checked);
                        }
                      }}
                      name={quickMessage ? "replyLink" : optOut}
                      placeholder={quickMessage ? "Add Reply Link" : "Add Opt-Out Link"}
                      disabled={values.campaign_type === "notification"}
                      ref={checkBoxRef}
                    />
                  </div>
                </Popconfirm>
              )}
            </>
          )}
        </Col>
      )}
      {values.replyLink && quickMessage && !user.quickApproved && (
        <>
          <Col span={colSpan} md={colMd}>
            <FormInput name="replyLinkMessage" placeholder="Enter Reply Page Text" value={values.replyLinkMessage} onChange={handleChange} size={size} containerClassname="mb-0 w-50" />
          </Col>
          {/* <Col span={colSpan} md={colMd}>
            <div>
              <FormCheckBoxInput
                containerClassname="mb-0"
                values={values}
                handleChange={handleChange}
                name={"includeLogo"}
                placeholder={"Include Logo on Reply Page"}
              />
            </div>
          </Col> */}
        </>
      )}
      {scheduler && (
        <Col className={`d-flex align-items-start`}>
          <CampaignSchedule setFieldValue={setFieldValue} values={values} error={errors.scheduleAt} touched={touched.scheduleAt} scheduler={scheduler} />
        </Col>
      )}
      {endProp && (
        <Col md={quickMessage ? 12 : colMd} span={colSpan}>
          <div className="mb-md-0 mb-3 d-flex">{endProp}</div>
        </Col>
      )}
    </>
  );
};

export default inject(stores => ({
  messageStore: stores.store.messageStore,
  templateStore: stores.store.templateStore,
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore
}))(observer(MessageContainer));
